var SiteBean = (function () {

    var p = SiteBean.prototype;

    function SiteBean(data) {
        this.albumsData = {
            selected: 2,
            list: data.albums
        };
        this.url = data.url;
        this.urlData = data.url + '/data';
        this.videosData = data.videos;
        this.interactivesData = data.interactives;
        this.storeData = data.store;
        this.interfaceData = data.interface;
        this.aboutData = data.about;
        this.destinyScreen = '';
        this.loading = true;
        this.hideMenu = false;
    }
    ;

    var isScreenShort = function(id) {
        return id === 'about'
            || id === 'contact'
            || id === 'store';
    };

    p._isCurrentScreen = function(screenId, index) {
        return (
            location.hash == '#/' + screenId &&
            (screenId !== 'albums' || index === this.albumsData.selected)
        );
    }

    p.switchAlbum = function (index) {
        var screenId = 'albums';
        if (!this._isCurrentScreen(screenId, index)) {
            this.albumsData.selected = index;
            this.goToScreen(screenId);
        }
    };

    p.goToDestiny = function() {
        location.hash = '#/' + this.destinyScreen;
        setTimeout(function() {
            document.getElementById('psicodelicPinkyScreen').scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 500);
    };

    p.goToScreen = function(screenId) {
        if (!this._isCurrentScreen(screenId, null)) {
            this.destinyScreen = screenId;
            location.hash = '#/empty';
            this.destinyScreenStyleClass = isScreenShort(screenId) ? 'short' : '';
        }
    };

    return SiteBean;

}());

var _sitebean_instance;

module.exports.getInstance = function () {
    if (!_sitebean_instance)
        _sitebean_instance = new SiteBean(psicodelicPinkyData);
    return _sitebean_instance;
};
