<template>
    <div id="loading" v-bind:style="this.currentStyle()"></div>
</template>
<script>

    export default {
        props: {
            progress: {
                type: Number,
                default: () => 0
            },
            color: {
                type: String,
                default: () => 'black'
            }
        },
        methods: {
            currentStyle() {
                return {width: this.progress + '%', background: this.color};
            }
        }
    }

</script>
<style>

    #loading {
        position: relative;
        height: 4px;
    }

</style>
