<template>
    <div class='albumBox' v-on:click='showInModal(galleryData)' >
        <div class='albumBoxInner' v-for='(i, iId) in galleryData.images' :class='{hidden : iId != visible}'>
            <img :src='[albumPath, galleryData.path, i.src].join("/")' ></img>
            <div class="albumDetails pointer">
                <h2 class="albumTitle">{{galleryData.title}}</h2>
                <h3 class="albumDescription">{{galleryData.subtitle}}</h3>
            </div>
        </div>
    </div>
</template>
<script>

    export default {
        props: {
            showInModal: {
                type: Function,
                default: () => {() => {}}
            },
            albumPath: {
                type: String,
                default: () => ''
            },
            galleryData: {
                type: Object,
                default: () => {}
            },
            images: {
                type: Array,
                default: () => []
            },
            loopTime: {
                type: Object,
                default: () => {}
            }
        },
        data() {
            return {
                intervalId: null,
                visible: 0
            };
        },
        methods: {
            switchImage() {
                this.visible = (this.visible+1)%this.galleryData.images.length;
            },
            startSwitching() {
                var f = this.loopTime.frequency;
                this.intervalId = setInterval(this.switchImage, Math.random() * (f.max - f.min) + f.min);
            }
        },
        destroyed() {
            clearInterval(this.intervalId)
        },
        mounted() {
            if (this.galleryData.loop)
                setTimeout(this.startSwitching, Math.random() * this.loopTime.maxBegin);
        }
    }
</script>
<style></style>
