<template>
    <div class='row' v-if='!bean.loading' :style='"background-color: #" + bean.storeData.list[0].color'>
        <div class='col-sm-4'></div>
        <div class='col-sm-4 text-center screenContent short'>
            <h1>
                {{bean.storeData.list[0].title}}
            </h1>
            <img class='fullWidth' :src='[bean.urlData, bean.storeData.path, bean.storeData.list[0].path, bean.storeData.list[0].articles[0].image].join("/")'></img>
        </div>
        <div class='col-sm-4'></div>
    </div>
</template>
<script>
    import SiteBean from '@/scripts/SiteBean.js';

    export default {
        data() {
            return {
                bean: SiteBean.getInstance()
            };
        }
    }

</script>
<style></style>
