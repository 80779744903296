var Utils = (function () {

    var p = Utils.prototype;

    function Utils() {}
    ;

    p.copy = function (text) {
        var ta = document.createElement('textarea');
        document.children[0].appendChild(ta);
        ta.innerHTML = text;
        ta.select();
        document.execCommand('copy');
        ta.parentNode.removeChild(ta);
    };

    p.getUrlParams = function () {
        var vars = {};
        window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
            vars[key] = value;
        });
        return vars;
    };

    p.eventFire = function (el, etype) {
        if (el.fireEvent) {
            el.fireEvent('on' + etype);
        } else {
            var evObj = document.createEvent('Events');
            evObj.initEvent(etype, true, false);
            el.dispatchEvent(evObj);
        }
    };

    p.goToLink = function(url) {
        if (url)
            window.open(url, '_blank');
    }

    return Utils;

}());

var _utils_instance;

module.exports.getInstance = function () {
    if (!_utils_instance)
        _utils_instance = new Utils();
    return _utils_instance;
};
