import DashboardLayout  from '@/layout/DashboardLayout.vue';

import PageNotFound     from '@/pages/404.vue';

import About            from '@/pages/About.vue';
import Albums           from '@/pages/Albums.vue';
import Animations       from '@/pages/Animations.vue';
import Contact          from '@/pages/Contact.vue';
import Empty            from '@/pages/Empty.vue';
import Interactives     from '@/pages/Interactives.vue';
import Projection       from '@/pages/Projection.vue';
import Projections      from '@/pages/Projections.vue';
import Store            from '@/pages/Coming soon.vue';
import StoreCS          from '@/pages/Store.vue';

const routes = [
    {
        path: '/',
        component: DashboardLayout,
        redirect: '/albums',
        children: [
            {
                path: 'about',
                name: 'about',
                component: About
            },
            {
                path: 'albums',
                name: 'albums',
                component: Albums
            },
            {
                path: 'animations',
                name: 'animations',
                component: Animations
            },
            {
                path: 'contact',
                name: 'contact',
                component: Contact
            },
            {
                path: 'empty',
                name: 'empty',
                component: Empty
            },
            {
                path: 'interactives',
                name: 'interactives',
                component: Interactives
            },
            {
                path: 'projection',
                name: 'projection',
                component: Projection
            },
            {
                path: 'projections',
                name: 'projections',
                component: Projections
            },
            {
                path: 'store',
                name: 'store',
                component: Store
            },
            {
                path: 'store_cs',
                name: 'store_cs',
                component: StoreCS
            }
        ]
    },
    {path: '*', component: PageNotFound}
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../pages/Views/' + name + '.vue');
   return res;
};**/

export default routes;
