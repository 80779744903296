<template>
    <div class='screenContent' :class='bean.destinyScreenStyleClass' ></div>
</template>
<script>
    import SiteBean from '@/scripts/SiteBean.js';

    export default {
        data() {
            return {
                bean: SiteBean.getInstance()
            };
        },
        mounted() {
            this.bean.goToDestiny();
        }
    }

</script>
<style></style>
