import { directive as vClickOutside } from "vue-clickaway";

import VueAnime from 'vue-animejs';
import VueHotkey from 'v-hotkey'

const GlobalDirectives = {
  install(Vue) {
    Vue.directive("click-outside", vClickOutside);
    Vue.use(VueAnime);
    Vue.use(VueHotkey);
  }
};

export default GlobalDirectives;
