import {
    BurgerMenu,
    Gallery,
    GridImages,
    InteractiveImage,
    Modal,
    Loading,
    Paralax
} from '../components/index';

const GlobalComponents = {
    install(Vue) {
        Vue.component('burger-menu', BurgerMenu);
        Vue.component('gallery-preview', Gallery);
        Vue.component('grid-images', GridImages);
        Vue.component('interactive-image', InteractiveImage);
        Vue.component('modal', Modal);
        Vue.component('loading', Loading);
        Vue.component('paralax', Paralax);
    }
};

export default GlobalComponents;
