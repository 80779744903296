<template>
    <div class="main-panel">
        <div class='hidden'>
            <h1>Ileana Zukernik</h1>
            <h2>personal art</h2>
            <h3>illustration, artist, illustrator, animation, video games</h3>
            <h3>illustración, artista, illustradora, animación, videojuegos</h3>
        </div>
        <top-navbar :bean='siteBean' v-if='!siteBean.hideMenu' ></top-navbar>
        <dashboard-content></dashboard-content>
        <burger-menu v-if='!siteBean.hideMenu' ></burger-menu>
        <content-footer></content-footer>
    </div>
</template>
<style lang="scss">
</style>
<script>
    import SiteBean from '@/scripts/SiteBean.js';

    import TopNavbar from "./TopNavbar.vue";
    import ContentFooter from "./ContentFooter.vue";
    import DashboardContent from "./Content.vue";

    export default {
        components: {
            TopNavbar,
            ContentFooter,
            DashboardContent
        },
        data() {
            return {
                siteBean: SiteBean.getInstance()
            };
        }
    };
</script>
