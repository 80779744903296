<template>
    <div v-if='!bean.loading' class='screenContent' >
        <div class='row' v-for='list in bean.videosData.list' :style='"background-color: #" + list.color'>
            <div class='col-sm-3 col-lg-4'></div>
            <div class='col-sm-6 col-lg-4 text-center' id='animationsScreen' >
                <h1>
                    {{list.title}}
                </h1>
                <div v-for='video in list.videos'>
                    <p>
                        {{video.description}}
                    </p>
                    <img v-if='!video.src' class='fullWidth' :src='[bean.urlData, bean.videosData.path, list.path, video.image].join("/")' ></img>
                    <div v-if=' video.src' >
                        <video class='fullWidth' :poster='[bean.urlData, bean.videosData.path, list.path, video.image].join("/")' :loop='video.loop' :autoplay='video.loop' controls >
                                <source :src='[bean.urlData, bean.videosData.path, list.path, video.src].join("/")' type='video/mp4'>
                                No es posible reproducir el video
                        </video>
                    </div>
                </div>
            </div>
            <div class='col-sm-3 col-lg-4'></div>
        </div>
    </div>
</template>
<script>
    import SiteBean from '@/scripts/SiteBean.js';

    export default {
        data() {
            return {
                bean: SiteBean.getInstance()
            };
        }
    }

</script>
<style></style>
