<template>
    <div class='row' id='projectionScreen' >
        <div class='col-sm-3'></div>
        <div class='col-sm-6 text-center' id='interactivesScreen' v-if='isImageVisible' >
            <interactive-image
                id='interactiveProjection'
                :url='siteBean.url'
                :interfaceUrl='[siteBean.urlData, siteBean.interfaceData.path].join("/")'
                :dataUrl='[siteBean.urlData, siteBean.interactivesData.path].join("/")'
                :soundEnable='false'
                :data='siteBean.interactivesData.list[projectionBean.interactiveSelected]'
                :projectionBean='projectionBean'>
            </interactive-image>
            <h1 id='userName' ></h1>
            <div v-if='!projectionBean.projecting'>
                <label>Nombre: </label>
                <input v-on:keyup='nameChanged' v-model='userName'></input>
            </div>
        </div>
        <div class='col-sm-3' ></div>
        <vue-q-art id='qrCode' :config="qr.data" v-if='projectionBean.projecting' v-hotkey="keyPressed" :style='"bottom:" + qr.style.bottom + "px; left:" + qr.style.left + "px; opacity:" + qr.style.opacity + "; transform:scale(" + qr.style.scale + ");"'></vue-q-art>
    </div>
</template>
<script>
    import VueQArt from 'vue-qart';

    import ProjectionBean from '@/scripts/ProjectionBean.js';
    import SiteBean from '@/scripts/SiteBean.js';
    import Utils from '@/scripts/Utils.js';

    export default {
        components: {
            VueQArt
        },
        data() {
            return {
                isImageVisible: true,
                projectionBean: ProjectionBean.getInstance(),
                siteBean: SiteBean.getInstance(),
                userName: '',
                qr: {
                    _firstOpacity: 1,
                    _speed: {
                        move: 3,
                        scale: 0.05
                    },
                    style: {
                        opacity: 1,
                        scale: 1,
                        left: 20,
                        bottom: 20
                    },
                    data: {
                        value: location.origin,
                        imagePath: 'favicon.png',
                        filter: 'color'
                    }
                }
            };
        },
        computed: {
            keyPressed () {
                return {
/*
                    'ctrl+esc': {
                        keydown: this.hide,
                        keyup: this.show
                    },
*/
                    'q': this._scaleDown,
                    'e': this._scaleUp,
                    'w': this._moveUp,
                    'a': this._moveLeft,
                    's': this._moveDown,
                    'd': this._moveRight
                };
            }
        },
        methods: {
            _showImage: function() {
                this.isImageVisible = true;
            },
            _scaleUp: function() {
                this.qr.style.scale += this.qr._speed.scale;
            },
            _scaleDown: function() {
                this.qr.style.scale -= this.qr._speed.scale;
            },
            _moveUp: function() {
                this.qr.style.bottom += this.qr._speed.move;
            },
            _moveDown: function() {
                this.qr.style.bottom -= this.qr._speed.move;
            },
            _moveLeft: function() {
                this.qr.style.left -= this.qr._speed.move;
            },
            _moveRight: function() {
                this.qr.style.left += this.qr._speed.move;
            },
            _showQRCode: function() {
                this.qr.style.opacity = 1;
            },
            _updateLink: function(link) {
                this.isImageVisible = false;
                setTimeout(this._showImage);
                this.qr.style.opacity = this.qr._firstOpacity;
                this.qr._firstOpacity = 0;
                setTimeout(this._showQRCode, 14000);
                this.qr.data.value = link;
                console.log(link);
            },
            nameChanged: function(event) {
                this.projectionBean.sendName(this.userName);
            },
            moveQRCode: function(event) {
                console.log(event);
            }
        },
        mounted() {
            this.siteBean.hideMenu = true;
            this.projectionBean.connect(this.siteBean, Utils.getInstance(), this._updateLink);
        },
        destroyed() {
            this.siteBean.hideMenu = false;
        }
    }

</script>
<style></style>
