<template>
    <quick-menu
        class='burgerMenu'
        :menu-count         ='menu.menuCount'
        :menu-url-list      ='menu.menuUrlList'
        :icon-class         ='menu.iconClass'
        :position           ='menu.position'
        :backgroundColor    ='menu.backgroundColor'
        :color              ='menu.color'
        :isOpenNewTab       ='menu.isOpenNewTab'
        ></quick-menu>
</template>
<script>
    import quickMenu from 'vue-quick-menu';

    export default {
        components: {
            quickMenu
        },
        data() {
            return {
                menu: {
                    menuCount: 3,
                    menuUrlList: [
                        {
                            isLink: true,
                            url: 'about'
                        },
                        {
                            isLink: true,
                            url: 'contact'
                        },
                        {
                            isLink: true,
                            url: 'store'
                        }
                    ],
                    iconClass: ['fa fa-user-circle-o', 'fa fa-envelope-o', 'fa fa-shopping-cart'],
                    position: 'top-left',
                    backgroundColor: '#545da9',
                    color: '#eb76ae',
                    isOpenNewTab: false
                }
            };
        }
    }

</script>
<style lang='scss'>

    .burgerMenu {
        top: -5px !important;
        left: -10px !important;
        cursor: pointer;

        .menu {
            transform: scale(0.55);
            box-shadow: none !important;

            .bar, .bar::before, .bar::after {
                background: #eb76ae !important;
            }

        }

        .sub-menu {
            transform: scale(0.75);
        }

    }

</style>
