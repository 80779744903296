<template>
    <div v-images-loaded:on.progress="imageProgress">
        <loading :progress="loadProgress" :color="loadingColor"></loading>
        <modal class='galleryModal' v-if="gallerySelected" @close="closeModal" :backgroundColor='albums.list[albums.selected].color'>
            <div slot='header' class='galleryModal-header'>
                <h2 class="albumTitle">{{gallerySelected.title}}</h2>
                <h3 class="albumDescription">{{gallerySelected.description}}</h3>
            </div>
            <div slot='body' class='galleryModal-body'>
                <div v-for='i in gallerySelected.images' >
                    <h3>{{i.title}}</h3>
                    <img v-if='!i.video' :src='[url, albums.list[albums.selected].path, gallerySelected.path, i.src].join("/")' v-on:click='utils.goToLink(i.link)' ></img>
                    <div v-if=' i.video' >
                        <video :poster='[url, albums.list[albums.selected].path, gallerySelected.path, i.src].join("/")' :loop='i.loop' :autoplay='i.loop' :controls='!i.loop' >
                                <source :src='[url, albums.list[albums.selected].path, gallerySelected.path, i.video].join("/")' type='video/mp4'>
                                No es posible reproducir el video
                        </video>
                    </div>
                </div>
            </div>
        </modal>
        <gallery-preview v-for='gallery in albums.list[albums.selected].list' :showInModal='showModal' :albumPath='[url, albums.list[albums.selected].path].join("/")' :galleryData='gallery' :images='gallery.images.filter((i) => !i.video)' :loopTime='albums.list[albums.selected].loopTime' ></gallery-preview>
    </div>
</template>
<script>
    import Utils from '@/scripts/Utils.js';

    import imagesLoaded from 'vue-images-loaded';

    export default {
        directives: {
            imagesLoaded
        },
        props: {
            loadingColor: {
                type: String,
                default: () => ''
            },
            url: {
                type: String,
                default: () => ''
            },
            albums: {
                type: Object,
                default: () => {}
            }
        },
        data() {
            return {
                utils: Utils.getInstance(),
                gallerySelected: null,
                imagesLoaded: 0,
                loadProgress: 0
            };
        },
        methods: {
            showModal(gallery) {
                this.gallerySelected = gallery;
                document.body.classList.add("scrollBlocked");
            },
            closeModal() {
                this.gallerySelected = null;
                document.body.classList.remove("scrollBlocked");
            },
            imageProgress(instance, image) {
                this.updateLoading(instance.images.length);
                image.img.setAttribute('data-vue-img-group', image.img.getAttribute('aux-img-group'));
                if (image.img.width > image.img.height)
                    image.img.className = 'landscape';
                else
                    image.img.className = 'portait';
            },
            updateLoading(totalImages) {
                this.imagesLoaded = (this.imagesLoaded + 1) % totalImages;
                this.loadProgress = this.imagesLoaded * 100 / totalImages;
            }
        },
        mounted() {
            var closeModal = this.closeModal;
            var popstate = function () {
                closeModal();
                history.pushState(null, null, document.URL);
            };
            history.pushState(null, null, document.URL);
            window.addEventListener('popstate', popstate);
        }
    }
</script>
<style></style>
