<template>
    <div class='row' v-if='!bean.loading' >
        <div class='col-sm-2'></div>
        <div class='col-sm-10 text-left screenContent short' id='aboutScreen' >
            <div class='row'>
                <div class='col-sm-3  text-center'>
                    <img :src='[bean.urlData, bean.aboutData.image].join("/")'></img>
                    <div class='socialLinks'>
                        <a :href='bean.aboutData.instagram' target='_blank'>
                            <i class='fa fa-instagram'></i>
                        </a>
                        <a :href='bean.aboutData.twitter' target='_blank'>
                            <i class='fa fa-twitter'></i>
                        </a>
                        <a v-on:click='bean.goToScreen("contact")' class='pointer'>
                            <i class='fa fa-envelope-o'></i>
                        </a>
                    </div>
                </div>
                <div class='col-sm-7 info'>
                    <h1>
                        {{bean.aboutData.title}}
                    </h1>
                    <p>
                        {{bean.aboutData.description}}
                    </p>
                </div>
                <div class='col-sm-2'></div>
            </div>
        </div>
    </div>
</template>
<script>
    import SiteBean from '@/scripts/SiteBean.js';

    export default {
        data() {
            return {
                bean: SiteBean.getInstance()
            };
        }
    }

</script>
<style></style>
