<template>
    <div class='row' v-if='!bean.loading' :style='"background-color: #" + bean.albumsData.list[bean.albumsData.selected].color'>
        <div class='col-sm-12 text-center screenContent'>
            <h1 class='albumTitle'>
                {{bean.albumsData.list[bean.albumsData.selected].title}}
            </h1>
            <grid-images :loadingColor='bean.interfaceData.loadingColor' :url='bean.urlData' :albums='bean.albumsData'></grid-images>
        </div>
    </div>
</template>
<script>
    import SiteBean from '@/scripts/SiteBean.js';

    export default {
        data() {
            return {
                bean: SiteBean.getInstance()
            };
        }
    }

</script>
<style></style>
