<template>
    <div class='row' >
        <div class='col-sm-1'></div>
        <div class='col-sm-10 text-center' id='interactivesScreen' >
            <h1>Proyecciones</h1>
            <div class='row' >
                <div class='col-sm-3' v-for='(image, index) in siteBean.interactivesData.list' v-if='image.image'>
                    <div class='fullWidth pointer' v-on:click='goToProjection(index)' >
                        <img :src='[siteBean.urlData, siteBean.interactivesData.path, image.image].join("/")' class='backgroundImage' ></img>
                        <img :src='[siteBean.urlData, siteBean.interactivesData.path, image.image.split(".")[0] + ".svg"].join("/")' ></img>
                    </div>
                </div>
            </div>
        </div>
        <div class='col-sm-1'></div>
    </div>
</template>
<script>
    import ProjectionBean from '@/scripts/ProjectionBean.js';
    import SiteBean from '@/scripts/SiteBean.js';

    export default {
        data() {
            return {
                projectionBean: ProjectionBean.getInstance(),
                siteBean: SiteBean.getInstance()
            };
        },
        methods: {
            goToProjection(index) {
                this.projectionBean.toProject();
                this.projectionBean.interactiveSelected = index;
                this.siteBean.goToScreen('projection');
            }
        }
    }

</script>
<style></style>
