<template>
    <div class='fullWidth'>
        <div v-if='data.interfaces'>
            <img :src='[dataUrl, data.image].join("/")' class='backgroundImage' ></img>
            <object :data='[dataUrl, data.image.split(".")[0] + ".svg"].join("/")' v-on:load='loadSvg' ></object>
            <div v-on:click='playAudio' class='audioButton' v-if='soundEnable' >
                <img v-if=' isAudioPlaying' :src='[interfaceUrl, "soundOn.svg"].join("/")' class='audioButtonOn' ></img>
                <img v-if='!isAudioPlaying' :src='[interfaceUrl, "soundOff.svg"].join("/")' ></img>
                <audio loop >
                    <source :src='[dataUrl, data.image.split(".")[0] + ".mp3"].join("/")' type="audio/mpeg">
                </audio>
            </div>
            <p class='text-left text-error' v-for='e in errors' ><b>Error:</b> en {{data.image.split(".")[0] + ".svg"}} falta el elemento con <b>id="{{e}}"</b></p>
        </div>
        <video v-on:click='utils.goToLink([url, data.video.split(".")[0]].join("/"))' v-if='data.video' class='fullWidth' loop autoplay >
            <source :src='[dataUrl, data.video].join("/")' type='video/mp4'>
            No es posible reproducir el video
        </video>
    </div>
</template>
<script>
    import Utils from '@/scripts/Utils.js';

    export default {
        props: {
            url: {
                type: String,
                default: () => ''
            },
            interfaceUrl: {
                type: String,
                default: () => ''
            },
            dataUrl: {
                type: String,
                default: () => ''
            },
            soundEnable: {
                type: Boolean,
                default: () => true
            },
            data: {
                type: Object,
                default: () => {}
            },
            projectionBean: {
                type: Object,
                default: () => {}
            }
        },
        data() {
            return {
                utils: Utils.getInstance(),
                isAudioPlaying: false,
                errors: []
            };
        },
        methods: {
            _checkError: function (a, b, aId, index) {
                if (!a && (b || index === 1))
                    this.errors.push(aId);
            },
            _checkInterface: function (parent, id, index) {
                id += index;
                var a = parent.getElementById(id);
                var b = parent.getElementById('area_' + id);
                this._checkError(a, b, id, index);
                this._checkError(b, a, 'area_' + id, index);
                return a && b;
            },
            _switchClassElement: function (parent, id, showing) {
                parent.getElementById('area_' + id).style.display = showing ? 'block' : 'none';
                parent.getElementById(id).style.opacity = showing;
            },
            _switchAction: function (event) {
                this.projectionBean.sendClick(event.target.id);
                var iId = event.target.id.replace('area_', '');
                var action = iId.match(/\d/g)[0];
                iId = iId.replace(action, '');
                var svg = event.target.parentNode;
                var actionNext = parseInt(action) + 1;
                if (!svg.getElementById(iId + actionNext) || !svg.getElementById('area_' + iId + actionNext))
                    actionNext = 1;
                if (!this.data.multipleFlows) {
                    for (var i of this.data.interfaces)
                        this._resetInterface(svg, i);
                    action = 1;
                }
                this._switchClassElement(svg, iId + action, 0);
                this._switchClassElement(svg, iId + actionNext, 1);
            },
            _resetInterface: function (svg, i) {
                this._switchClassElement(svg, i.id + 1, 1);
                for (var action = 2; svg.getElementById(i.id + action); action++)
                    this._switchClassElement(svg, i.id + action, 0);
            },
            _initAnimation: function (element, time) {
                element.style.transition = (time / 1000) + 's';
                element.style.pointerEvents = 'none';
            },
            _initActions: function (svg, i, index) {
                if (this._checkInterface(svg, i.id, index)) {
                    svg.getElementById('area_' + i.id + index).addEventListener('click', this._switchAction);
                    this._initAnimation(svg.getElementById(i.id + index), i.transitionTime);
                    this._initActions(svg, i, index + 1);
                }
            },
            loadSvg: function (event) {
                var svg = event.target.contentDocument;
                for (var i of this.data.interfaces) {
                    this._initActions(svg, i, 1);
                    this._resetInterface(svg, i);
                }
                svg.onmousemove = document.onmousemove;
            },
            _audioStarted: function (event) {
                this.isAudioPlaying = true;
            },
            _audioPaused: function (event) {
                this.isAudioPlaying = false;
            },
            playAudio: function (event) {
                var audio = event.target.parentNode.getElementsByTagName('audio')[0];
                if (audio.paused) {
                    for (var otherAudio of document.getElementsByClassName('audioButtonOn'))
                        otherAudio.click();
                    audio.play();
                    audio.onplay = this._audioStarted;
                    audio.onpause = this._audioPaused;
                } else
                    audio.pause();
            }
        }
    }

</script>
<style></style>
