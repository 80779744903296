<template>
    <div v-if='!siteBean.loading' class='screenContent' >
        <div class='row' v-for='image in siteBean.interactivesData.list' :style='"background-color: #" + image.color' >
            <div class='col-sm-3 col-lg-4'></div>
            <div class='col-sm-6 col-lg-4 text-center' id='interactivesScreen' >
                <h1>
                    {{image.title}}
                </h1>
                <p>
                    {{image.description}}
                </p>
                <interactive-image
                    :url='siteBean.url'
                    :interfaceUrl='[siteBean.urlData, siteBean.interfaceData.path].join("/")'
                    :dataUrl='[siteBean.urlData, siteBean.interactivesData.path].join("/")'
                    :soundEnable='true'
                    :data='image'
                    :projectionBean='projectionBean'>
                </interactive-image>
            </div>
            <div class='col-sm-3 col-lg-4'></div>
        </div>
    </div>
</template>
<script>
    import ProjectionBean from '@/scripts/ProjectionBean.js';
    import SiteBean from '@/scripts/SiteBean.js';

    export default {
        data() {
            return {
                projectionBean: ProjectionBean.getInstance(),
                siteBean: SiteBean.getInstance()
            };
        },
        mounted() {
            this.projectionBean.toDisconnected();
        }
    }

</script>
<style></style>
