<template>
    <div v-if='!bean.loading' class='screenContent' >
        <div class='row' v-for='store in bean.storeData.list' :style='"background-color: #" + store.color' >
            <div class='col-sm-1'></div>
            <div class='col-sm-10 text-center'>
                <h1>
                    {{store.title}}
                </h1>
                <div class='row'>
                    <div class='col-sm-3' v-for='article in store.articles'>
                        <h1 class='text-left'>
                            {{article.description}}
                        </h1>
                        <p class='text-right'>
                            <b>{{article.price}}</b>
                        </p>
                        <a :href='article.link' target='_blank'>
                            <img class='fullWidth' :src='[bean.urlData, bean.storeData.path, store.path, article.image].join("/")'></img>
                        </a>
                    </div>
                </div>
            </div>
            <div class='col-sm-1'></div>
        </div>
    </div>
</template>
<script>
    import SiteBean from '@/scripts/SiteBean.js';

    export default {
        data() {
            return {
                bean: SiteBean.getInstance()
            };
        }
    }

</script>
<style></style>
