var ProjectionBean = (function () {

    var
        p = ProjectionBean.prototype,
        that;

    function ProjectionBean() {
        that = this;
        this.interactiveSelected = 0;
        this.projecting = false;
        this.onOpen = onOpenPlaying;
        this.onMessage = onMessagePlaying;
        this._sendMessage = _sendMessagePlaying;
    };

    var onClose = function(event) {
        that.socket.close();
        that._sendMessage = _sendMessageDisconnected;
    };

    var onOpenProjecting = function(event) {
        that.socket.send('projector: 1');
    };

    var onOpenPlaying = function(event) {
        var params = that.utils.getUrlParams();
        that.interactiveSelected = params.id;
        if (params.id && params.token) {
            that.socket.send('token: ' + params.token);
        } else {
            onClose(event);
            that.siteBean.goToScreen('');
        }
    };

    var onMessageProjecting = function(message) {
        var message = message.data.split(': ');
        switch(message[0]) {
            case ('token'):
                that.updateTokenFx(location.href + '?id=' + that.interactiveSelected + '&token=' + message[1]);
                break;
            case ('click'):
                that.utils.eventFire(document.getElementById('interactiveProjection').getElementsByTagName('object')[0].contentDocument.getElementById(message[1]), 'click');
                break;
            case ('name'):
                document.getElementById('userName').innerHTML = message[1];
                break;
        }
    };

    var onMessagePlaying = function(message) {};

    var _sendMessageDisconnected = function(key, value) {};

    var _sendMessageProjecting = _sendMessageDisconnected;

    var _sendMessagePlaying = function(key, value) {
        that.socket.send(key + ': ' + value);
    };

    p.connect = function(siteBean, utils, updateTokenFx) {
        this.siteBean = siteBean;
        this.utils = utils;
        this.updateTokenFx = updateTokenFx;
        try {
            this.socket = new WebSocket('wss://' + location.hostname + ':8081');
            this.socket.onopen = this.onOpen;
            this.socket.onclose = onClose;
            this.socket.onmessage = this.onMessage;
        } catch(ex) {
            console.log(ex);
            this.siteBean.goToScreen('');
        }
    };

    p.toDisconnected = function() {
        this._sendMessage = _sendMessageDisconnected;
    };

    p.toProject = function() {
        this.projecting = true;
        this.onOpen = onOpenProjecting;
        this.onMessage = onMessageProjecting;
        this._sendMessage = _sendMessageProjecting;
    };

    p.sendClick = function(click) {
        this._sendMessage('click', click);
    };

    p.sendName = function(name) {
        this._sendMessage('name', name);
    };

    return ProjectionBean;

}());

var _projectionbean_instance;

module.exports.getInstance = function () {
    if (!_projectionbean_instance)
        _projectionbean_instance = new ProjectionBean();
    return _projectionbean_instance;
};
