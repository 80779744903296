<template>
    <div class='paralax' v-if='isEnable' >
        <object :data='[bean.urlData, bean.interfaceData.path, paralax.image + ".svg"].join("/")' v-on:load='loadSvg' ></object>
    </div>
</template>
<script>

    export default {
        props: {
            bean: {
                type: Object,
                default: () => {}
            },
            paralax: {
                type: Object,
                default: () => {}
            },
            endHour: {
                type: Number,
                default: () => 0
            }
        },
        data() {
            return {
                isEnable: false,
                position: {
                    x: 0,
                    y: 0
                },
                touchStartPosition: {
                    clientX: 0,
                    clientY: 0
                },
                layers: []
            };
        },
        methods: {
            _moveElement: function(deltaX, deltaY, sensitivity) {
                this.position.x = Math.max(-0.5, Math.min(0.5, this.position.x + deltaX / sensitivity));
                this.position.y = Math.max(-0.5, Math.min(0.5, this.position.y + deltaY / sensitivity));
                var speed = {
                    x: 12,
                    y: 0
                };
                var initial = {
                    x: 3,
                    y: 1
                };
                for (var i in this.layers)
                    this.layers[i].style.transform = 'translate('
                        + (this.position.x * speed.x * (i + initial.x)) + 'px,'
                        + (this.position.y * speed.y * (i + initial.y)) + 'px)';
            },
            _touchStart: function(event) {
                this.touchStartPosition = event.touches[0];
            },
            _touchMove: function(event) {
                var t = event.touches[0];
                this._moveElement(
                    t.clientX - this.touchStartPosition.clientX,
                    t.clientY - this.touchStartPosition.clientY,
                    500
                );
                this.touchStartPosition = t;
            },
            _mouseMove: function(event) {
                this._moveElement(
                    event.movementX,
                    event.movementY,
                    1200
                );
            },
            _getLayers: function(svg, index) {
                var layer = svg.getElementById('paralax' + index);
                if (!layer)
                    return [];
                layer.style.transition = '0.1s';
                return [layer].concat(this._getLayers(svg, index+1));
            },
            loadSvg: function(event) {
                var svg = event.target.contentDocument;
                this.layers = this._getLayers(svg, 1);
                for (var e of [svg, document]) {
                    e.onmousemove = this._mouseMove;
                    e.addEventListener("touchstart", this._touchStart, false);
                    e.addEventListener("touchmove", this._touchMove, false);
                }
            }
        },
        mounted() {
            var hour = new Date().getHours();
            var start = this.paralax.startHour;
            var end = this.endHour;
            if (start < end)
                this.isEnable = hour >= start && hour < end;
            else
                this.isEnable = hour >= start || hour < end;
        }
    }

</script>
<style></style>
